import "./App.css";
import Header from "./components/Header";
import Swap from "./components/Swap";
import Burn from "./components/Burn";
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import { BrowserProvider, Contract, formatUnits, MaxUint256 } from 'ethers';
import React, { useState, useEffect, useCallback } from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Lottery from './components/Lottery';
import Docs from './components/Docs';
import { TokenAddress, LotteryAddress } from './config';
import { Input, Popover, Radio, Modal, message } from 'antd';

const LotteryABI = require('./abi/Lottery.json').abi;
const tokenABI = require('./abi/IBEP20.json').abi;


function App() {
  const location = useLocation(); // Get the current route path
  const isSwapRoute = location.pathname.startsWith("/Swap");
  return (
    <div className="App">
      <Header />
      <div className={`mainWindow ${isSwapRoute ? "swapMargin" : ""}`}>
        <Routes>
          <Route path="/" element={<Navigate to="/Swap" />} />
          <Route path="/Lottery" element={<Lottery
          />} />
          <Route path="/Swap/:ref?" element={<Swap />} />
          <Route path="/Burn" element={<Burn />} />
        </Routes>
      </div>
    </div>

  );
}

export default App;
