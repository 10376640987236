import { Input, Popover, Radio, Modal, message } from 'antd';
import { SettingOutlined, CopyOutlined } from '@ant-design/icons';
import { useState, useEffect } from 'react';
import tokenList from '../tokenList.json';
import { ethers, parseUnits, BrowserProvider, Contract, formatUnits, isAddress } from 'ethers';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import BigNumber from 'bignumber.js';
import { PancakeSwapRouterAddress, TokenAddress, ProfitPortalAddress } from '../config';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { sign } from 'viem/accounts';
import DexScreenerEmbed from './DexScreener';

const tokenABI = require('../abi/CrashCoin.json').abi;
const pancakeSwapRouterABI = require('../abi/IUniswapV2Router01.json').abi;
const profitPortalABI = require('../abi/ProfitPortal.json').abi;

function Swap(props) {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const [slippage, setSlippage] = useState(0.5);
    const [messageApi, contextHolder] = message.useMessage();
    const [tokenOneAmount, setTokenOneAmount] = useState(0);
    const [tokenTwoAmount, setTokenTwoAmount] = useState(0);
    const [tokenOne, setTokenOne] = useState(tokenList[0]);
    const [tokenTwo, setTokenTwo] = useState(tokenList[1]);
    const [isOpen, setIsOpen] = useState(false);
    const [changeToken, setChangeToken] = useState(1);
    const [prices, setPrices] = useState({});
    const [txDetails, setTxDetails] = useState({
        to: null,
        data: null,
        value: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [referrer, setReferrer] = useState(null);
    const [defaultReferrer, setDefaultReferrer] = useState(null);
    const [isActive, setIsActive] = useState(true);
    const { ref } = useParams();
    const [customSlippage, setCustomSlippage] = useState(0.5); // State for custom slippage input
    const [slippageType, setSlippageType] = useState('predefined');
    const [balance, setBalance] = useState(0);
    const [isPartner, setIsPartner] = useState(false);
    const [isPartnerReferralEnabled, setIsPartnerReferralEnabled] = useState(false);
    const [splitReward, setSplitReward] = useState(false);

    const handleSlippage = (e) => {
        const value = parseFloat(e.target.value);
        if (slippageType === 'predefined') {
            setSlippage(value);
        } else {
            if (value < 0.1 || value > 49 || value === '' || isNaN(value)) {
                setCustomSlippage(value);
                setSlippage(0.5);
            }
            else if (value >= 0.1 && value <= 49) {
                setCustomSlippage(value);
                setSlippage(value);
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: 'Custom slippage must be between 0.1 and 49',
                    duration: 2,
                });
            }
        }
    };

    const handleSlippageTypeChange = (e) => {
        const value = e.target.value;
        setSlippageType(value);
        if (value === 'custom') {
            setSlippage(parseFloat(customSlippage) || 0.5); // Set to minimum value if empty
        } else {
            setSlippage(0.5); // Reset to default predefined slippage
        }
    };

    const changeAmount = (e) => {
        const value = e.target.value;
        setTokenOneAmount(value);
    };

    const setAmountPercentage = (percentage) => {
        let amnt = ethers.getBigInt(balance) * parseUnits(String(percentage * 10000), 0) / parseUnits(String(1000000), 0);
        if (amnt >= balance) {
            setTokenOneAmount(formatUnits(balance, tokenOne.decimals).toString());
        } else {
            setTokenOneAmount(formatUnits(amnt, tokenOne.decimals).toString());
        }
    };

    const isValidBscAddress = (address) => {
        return isAddress(address);
    };

    useEffect(() => {
        const getStatus = async () => {
            if (isConnected) {
                const ethersProvider = new BrowserProvider(walletProvider);
                const profitPortalContract = new Contract(ProfitPortalAddress, profitPortalABI, ethersProvider);
                const isActive = await profitPortalContract.isReferralEnabled();
                setIsActive(isActive);
                const signer = await ethersProvider.getSigner();
                const bal = await ethersProvider.getBalance(signer.getAddress());
                setBalance(bal);
                const isPartnerReferralEnabled = await profitPortalContract.isPartnerReferralEnabled();
                setIsPartnerReferralEnabled(isPartnerReferralEnabled);
            }
        };

        getStatus();
    }, [isConnected, walletProvider, address]);

    useEffect(() => {
        async function fetchReferrer() {
            if (isConnected && walletProvider) {
                let marketingWallet = null;
                const ethersProvider = new BrowserProvider(walletProvider);
                const tokenContract = new Contract(TokenAddress, tokenABI, ethersProvider);
                const profitPortalContract = new Contract(ProfitPortalAddress, profitPortalABI, ethersProvider);
                try {
                    marketingWallet = await tokenContract.marketingWallet();
                    if (marketingWallet) {
                        setReferrer(marketingWallet);
                        setDefaultReferrer(marketingWallet);
                    }
                } catch (error) {
                    console.error("Error fetching default referrer:", error);
                }

                const urlReferrer = ref;
                const storedReferrer = localStorage.getItem('ref');

                if (urlReferrer && isValidBscAddress(urlReferrer) && urlReferrer !== address) {
                    setReferrer(urlReferrer);
                    localStorage.setItem('ref', urlReferrer);
                } else if (storedReferrer && isValidBscAddress(storedReferrer)) {
                    setReferrer(storedReferrer);
                }
                if (ref) {
                    const isPartner = (await profitPortalContract.partners(ref)).isWhitelisted;
                    setIsPartner(isPartner);
                    const splitReward = (await profitPortalContract.partners(ref)).splitReward;
                    setSplitReward(splitReward);

                }
            }
        }

        fetchReferrer();
    }, [isConnected, walletProvider, ref, TokenAddress, tokenABI]);


    const modifyToken = (i) => {
        setPrices(null);
        setTokenOneAmount(0);
        setTokenTwoAmount(0);
        if (changeToken === 1) {
            setTokenOne(tokenList[i]);
            fetchDexSwap(tokenList[i].address, tokenTwo.address);
        } else {
            setTokenTwo(tokenList[i]);
            fetchDexSwap(tokenOne.address, tokenList[i].address);
        }
        setIsOpen(false);
    };

    const fetchDexSwap = async (one, two) => {
        if (isConnected && tokenOneAmount > 0) {
            const ethersProvider = new BrowserProvider(walletProvider);
            const router = new Contract(PancakeSwapRouterAddress, pancakeSwapRouterABI, ethersProvider);
            const inputBig = parseUnits(tokenOneAmount.toString(), tokenOne.decimals) * ethers.getBigInt(9500000) / ethers.getBigInt(10000000);
            const amounts = await router.getAmountsOut(inputBig, [one, two]);
            setTokenTwoAmount((new BigNumber(formatUnits(amounts[1].toString(), tokenTwo.decimals))));
        } else {
            setTokenTwoAmount(0);
        }
    };

    const buy = async () => {
        if (isConnected) {

            const ethersProvider = new BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner();
            const profitPortalContract = new Contract(ProfitPortalAddress, profitPortalABI, signer);
            const slip = (100 - slippage.toFixed(1)) * 100;
            try {
                if (isPartner && isPartnerReferralEnabled) {
                    const tx = await profitPortalContract.buyThroughPartnerReferral(referrer, parseUnits(tokenTwoAmount.toString(), tokenTwo.decimals) * ethers.getBigInt(slip) / ethers.getBigInt(10000), { value: parseUnits(tokenOneAmount, tokenOne.decimals) });
                    setTxDetails(tx);
                } else {
                    const tx = await profitPortalContract.buyWithReferral(referrer, parseUnits(tokenTwoAmount.toString(), tokenTwo.decimals) * ethers.getBigInt(slip) / ethers.getBigInt(10000), { value: parseUnits(tokenOneAmount, tokenOne.decimals) });
                    setTxDetails(tx);
                }
            } catch (error) {
                console.error(error);
                messageApi.error('Swap failed');
            }
        }
    };

    const sendTransaction = async (tx) => {
        try {
            setIsLoading(true);
            await tx.wait();
            setIsSuccess(true);
            setTokenOneAmount(0);
        } catch (error) {
            setIsSuccess(false);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isConnected) {
            fetchDexSwap(tokenList[0].address, tokenList[1].address);
        }
    }, [tokenOneAmount]);

    useEffect(() => {
        messageApi.destroy();
        if (isLoading) {
            messageApi.open({
                content: 'Waiting for transaction to be mined',
                type: 'loading',
                duration: 0,
            });
        }
    }, [isLoading]);

    useEffect(() => {
        messageApi.destroy();
        if (isSuccess) {
            messageApi.open({
                type: 'success',
                content: 'Transaction Success',
                duration: 2,
            });
        } else if (txDetails.to) {
            messageApi.open({
                type: 'error',
                content: 'Transaction Failed',
                duration: 2,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (txDetails.to && isConnected) {
            sendTransaction(txDetails);
            // message.success('Transaction sent');
        }
    }, [txDetails]);

    const settingsContent = (
        <>
            <div>Slippage Tolerance</div>
            <div>
                <Radio.Group onChange={handleSlippageTypeChange} value={slippageType}>
                    <Radio.Button value="predefined">Predefined</Radio.Button>
                    <Radio.Button value="custom">Custom</Radio.Button>
                </Radio.Group>
            </div>
            {slippageType === 'predefined' && (
                <div>
                    <Radio.Group onChange={handleSlippage} value={slippage}>
                        <Radio.Button value={0.1}>0.1%</Radio.Button>
                        <Radio.Button value={0.5}>0.5%</Radio.Button>
                        <Radio.Button value={2.5}>2.5%</Radio.Button>
                        <Radio.Button value={5}>5%</Radio.Button>
                    </Radio.Group>
                </div>
            )}
            {slippageType === 'custom' && (
                <div style={{ marginTop: '2px' }}>
                    <div>Custom Slippage</div>
                    <Input
                        className="no-spinner custom-height"
                        style={{ width: '50px', marginTop: '2px', fontSize: '0.8em' }}
                        placeholder="0.5"
                        type="number"
                        step={0.1}
                        min={0.1}
                        max={49}
                        value={customSlippage}
                        onChange={handleSlippage}
                    />
                </div>
            )}
        </>
    );

    const shortenAddress = (address) => {
        if (!address || address.length < 10) return address; // Return the original address if invalid or too short
        return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };


    const copyToClipboard = () => {
        console.log('copying to clipboard', address);
        if (address) {
            navigator.clipboard.writeText(`https://app.crashcoin.fun/Swap/${address}`);
            messageApi.open({
                type: 'info',
                content: 'Copied to clipboard!',
                duration: 2,
            });
        } else {
            messageApi.open({
                type: 'Error',
                content: 'Connect your wallet first!',
                duration: 2,
            });
        }
    };


    const title = 'CrashCoin Referral Buy';
    const description = 'CrashCoin Referral Buy';
    const imageUrl = `${process.env.PUBLIC_URL}/logo192.webp`;
    const url = 'https://app.crashcoin.fun/Swap';
    return (
        <>
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={imageUrl} />
                    <meta property="og:url" content={url} />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:image" content={imageUrl} />
                </Helmet>
                {/* <h2>{title}</h2>
                <p>{description}</p>
                <img src={imageUrl} alt={title} /> */}
            </div>
            {contextHolder}
            {/* <Modal open={isOpen} footer={null} onCancel={() => setIsOpen(false)} title="Select a token">
                <div className="modalContent">
                    {tokenList?.map((token, index) => (
                        <div className="tokenChoice" key={index} onClick={() => modifyToken(index)}>
                            <img src={token.img} alt={token.ticker} className="tokenLogo" />
                            <div className="tokenChoiceNames">
                                <div className="tokenName">{token.name}</div>
                                <div className="tokenTicker">{token.ticker}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </Modal> */}
            <div className="mainContent">
                {/* DexScreener Chart Section */}
                <div className="dexScreenerChart">
                    <DexScreenerEmbed />
                </div>
                <div className="swapBox">
                    <div className="swapBoxHeader">
                        <h4>Referral Buy (1% Refund)</h4>
                        <Popover title="Settings" trigger="click" placement="bottomRight" content={settingsContent}>
                            <SettingOutlined className="cog" />
                        </Popover>
                    </div>
                    <div className="inputs">
                        <Input placeholder="0" value={tokenOneAmount} onChange={changeAmount} disabled={!prices} type='number' className="no-spinner input-box" />
                        <div className="referrer-container">
                            <div className="leftH" style={{ fontSize: '1.0em', marginLeft: '5px' }}>
                                <div className="balance-text">
                                    Balance: {Number(formatUnits(balance, tokenOne.decimals)).toFixed(5)} BNB
                                </div>
                            </div>
                            <div className="rightH" style={{ fontSize: '0.8em', marginRight: '5px' }}>
                                <div className="input-container">
                                    <div className="input-buttons">
                                        <button onClick={() => setAmountPercentage(25)}>25%</button>
                                        <button onClick={() => setAmountPercentage(50)}>50%</button>
                                        <button onClick={() => setAmountPercentage(75)}>75%</button>
                                        <button onClick={() => setAmountPercentage(100)}>MAX</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Input placeholder="0" value={tokenTwoAmount.toFixed(4)} disabled />
                        <div className="assetOne" >
                            <img src={`${process.env.PUBLIC_URL}/bnb-logo.svg`} style={{ width: '30px', height: '30px' }} alt="assetOnelogo" className="logo" />
                            {tokenOne.ticker}
                        </div>
                        <div className="assetTwoBuy" >
                            <img src={`${process.env.PUBLIC_URL}/logo192.webp`} style={{ width: '30px', height: '30px' }} alt="assetTwologo" className="logo" />
                            {tokenTwo.ticker}
                        </div>
                    </div>
                    {referrer && referrer !== defaultReferrer && (
                        <div className="referrer-container">
                            <div className="leftH" style={{ fontSize: '0.8em', marginLeft: '5px' }}>Referrer: </div>
                            <div className="rightH" style={{ fontSize: '0.8em', marginRight: '5px' }}>{shortenAddress(referrer)}</div>
                        </div>
                    )}
                    {tokenOneAmount > 0 && (
                        isPartner ? (
                            splitReward ? (
                                <div className="referrer-container">
                                    <div className="leftH" style={{ fontSize: '0.8em', marginLeft: '5px' }}>Buyer 2.5% Refund: </div>
                                    <div className="rightH" style={{ fontSize: '0.8em', marginRight: '5px' }}>{(tokenOneAmount * 0.025).toFixed(4)} BNB</div>
                                </div>
                            ) : null // Do not show anything if isPartner is true but splitReward is false
                        ) : (
                            <div className="referrer-container">
                                <div className="leftH" style={{ fontSize: '0.8em', marginLeft: '5px' }}>Buyer 1% Refund: </div>
                                <div className="rightH" style={{ fontSize: '0.8em', marginRight: '5px' }}>{(tokenOneAmount * 0.01).toFixed(4)} BNB</div>
                            </div>
                        )
                    )}

                    {referrer && referrer !== defaultReferrer && tokenOneAmount > 0 && (
                        <div className="referrer-container">
                            {isPartner ? (
                                splitReward ? (
                                    // Partner with split reward, show 2.5%
                                    <>
                                        <div className="leftH" style={{ fontSize: '0.8em', marginLeft: '5px' }}>Referrer 2.5% Reward: </div>
                                        <div className="rightH" style={{ fontSize: '0.8em', marginRight: '5px' }}>{(tokenOneAmount * 0.025).toFixed(4)} BNB</div>
                                    </>
                                ) : (
                                    // Partner without split reward, show 5%
                                    <>
                                        <div className="leftH" style={{ fontSize: '0.8em', marginLeft: '5px' }}>Referrer 5% Reward: </div>
                                        <div className="rightH" style={{ fontSize: '0.8em', marginRight: '5px' }}>{(tokenOneAmount * 0.05).toFixed(4)} BNB</div>
                                    </>
                                )
                            ) : (
                                // Non-partner, show 1%
                                <>
                                    <div className="leftH" style={{ fontSize: '0.8em', marginLeft: '5px' }}>Referrer 1% Reward: </div>
                                    <div className="rightH" style={{ fontSize: '0.8em', marginRight: '5px' }}>{(tokenOneAmount * 0.01).toFixed(4)} BNB</div>
                                </>
                            )}
                        </div>
                    )}

                    <div className="referrer-container">
                        <div className="leftH" style={{ fontSize: '0.8em', marginLeft: '5px' }}>Slippage: </div>
                        <div className="rightH" style={{ fontSize: '0.8em', marginRight: '5px' }}>{slippage.toFixed(1)}%</div>
                    </div>

                    {tokenOneAmount > 0 && (
                        <div className="referrer-container">
                            <div className="leftH" style={{ fontSize: '0.8em', marginLeft: '5px' }}>Minimum Received: </div>
                            <div className="rightH" style={{ fontSize: '0.8em', marginRight: '5px' }}>{Number(formatUnits(parseUnits(tokenTwoAmount.toString(), tokenTwo.decimals) * parseUnits(((100 - slippage.toFixed(1)) * 100).toString(), 0) / parseUnits(String(10000), 0), tokenTwo.decimals).toString()).toFixed(2)} CRASH</div>
                        </div>
                    )}

                    <div className="Button" onClick={buy} disabled={tokenOneAmount === 0 || !isConnected}>
                        Buy
                    </div>

                    {!isActive && !isPartner && <div className='status inactive'>
                        Referral system is inactive
                    </div>
                    }

                    <div className="referral-link-section">
                        <div className="referral-link-label">Your Referral Link:</div>
                        <div className="referral-link-container">
                            <span className="referral-link">https://app.crashcoin.fun/Swap/{address === undefined || address === null ? "" : address}</span>
                            <CopyOutlined className="copy-icon" onClick={copyToClipboard} />
                        </div>
                    </div>

                </div>

            </div>


        </>
    );
}

export default Swap;
