import { Input, Radio, message } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import tokenList from '../tokenList.json';
import { ethers, parseUnits, BrowserProvider, Contract, formatUnits, MaxUint256 } from 'ethers';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react';
import BigNumber from 'bignumber.js';
import { PancakeSwapRouterAddress, TokenAddress, ProfitPortalAddress } from '../config';
import { Helmet } from 'react-helmet';
const tokenABI = require('../abi/CrashCoin.json').abi;
const pancakeSwapRouterABI = require('../abi/IUniswapV2Router01.json').abi;
const profitPortalABI = require('../abi/ProfitPortal.json').abi;

function Burn(props) {
    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();
    const [slippage, setSlippage] = useState(0.5);
    const [messageApi, contextHolder] = message.useMessage();
    const [tokenOneAmount, setTokenOneAmount] = useState(0);
    const [tokenTwoAmount, setTokenTwoAmount] = useState(0);
    const [tokenOne, setTokenOne] = useState(tokenList[1]);
    const [tokenTwo, setTokenTwo] = useState(tokenList[0]);
    const [isOpen, setIsOpen] = useState(false);
    const [changeToken, setChangeToken] = useState(1);
    const [prices, setPrices] = useState({});
    const [txDetails, setTxDetails] = useState({
        to: null,
        data: null,
        value: null,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [referrer, setReferrer] = useState(null);
    const [defaultReferrer, setDefaultReferrer] = useState(null);
    const [allowance, setAllowance] = useState(0);
    const [approval, setApproval] = useState(true);
    const [balance, setBalance] = useState(0);
    const [liquidity, setLiquidity] = useState(0);
    const [burnedBalance, setBurnedBalance] = useState(0);
    const [isActive, setIsActive] = useState(true);
    const [minimumTokenAmount, setMinimumTokenAmount] = useState(0);
    const [isWhitelisted, setIsWhitelisted] = useState(false);

    const handleSlippage = (e) => {
        setSlippage(e.target.value);
    };

    const changeAmount = (e) => {
        const value = e.target.value;
        setTokenOneAmount(value ? value : null);
    };

    const setAmountPercentage = (percentage) => {
        let amnt = ethers.getBigInt(balance) * parseUnits(String(percentage * 10000), 0) / parseUnits(String(1000000), 0);
        if (amnt >= balance) {
            setTokenOneAmount(formatUnits(balance, tokenOne.decimals).toString());
        } else {
            setTokenOneAmount(formatUnits(amnt, tokenOne.decimals).toString());
        }
    };

    useEffect(() => {
        const getStatus = async () => {
            if (isConnected) {
                const ethersProvider = new BrowserProvider(walletProvider);
                const profitPortalContract = new Contract(ProfitPortalAddress, profitPortalABI, ethersProvider);
                const isActive = await profitPortalContract.isProfitTakingEnabled();
                setIsActive(isActive);
                const isWhitelisted = await profitPortalContract.profitTakingWhitelist(address);
                setIsWhitelisted(isWhitelisted);
            }
        };

        getStatus();
    }, [isConnected, walletProvider, address]);

    useEffect(() => {
        async function fetchReferrer() {
            if (isConnected) {
                const ethersProvider = new BrowserProvider(walletProvider);
                try {
                    const tokenContract = new Contract(TokenAddress, tokenABI, ethersProvider);

                    const defaultReferrer = await tokenContract.teamWallet();
                    if (defaultReferrer) setDefaultReferrer(defaultReferrer);
                } catch (error) {
                    console.error(error);
                }
                const queryParams = new URLSearchParams(window.location.search);
                const urlReferrer = queryParams.get('ref');
                const storedReferrer = localStorage.getItem('ref');

                if (urlReferrer) {
                    setReferrer(urlReferrer);
                    localStorage.setItem('ref', urlReferrer);
                }
                else if (storedReferrer) {
                    setReferrer(storedReferrer);
                }
                else {
                    setReferrer(defaultReferrer);
                }
            }
        }
        fetchReferrer();
    }, [referrer]);

    useEffect(() => {
        const setupProvider = async () => {
            if (isConnected) {
                const ethersProvider = new BrowserProvider(walletProvider);
                const signer = await ethersProvider.getSigner();
                fetchData(signer);
                fetchDexSwap(tokenList[1].address, tokenList[0].address);
            }
        };

        setupProvider();
    }, [isConnected, walletProvider, address]);

    const fetchData = useCallback(async (signer) => {
        const ethersProvider = new BrowserProvider(walletProvider);
        const TokenContract = new Contract(TokenAddress, tokenABI, signer);
        const signerAllowance = await TokenContract.allowance(address, ProfitPortalAddress);
        const profitPortalContract = new Contract(ProfitPortalAddress, profitPortalABI, signer);
        setAllowance(signerAllowance);
        if (Number(signerAllowance) === 0)
            setApproval(true);
        else
            setApproval(false);
        const balance = await TokenContract.balanceOf(address);
        setBalance(balance);
        const profitPortalBalance = await ethersProvider.getBalance(ProfitPortalAddress);
        setLiquidity(profitPortalBalance);
        const deadBalance = await TokenContract.balanceOf('0x000000000000000000000000000000000000dEaD');
        setBurnedBalance(deadBalance);

        const minimumTokenAmount = await profitPortalContract.minimumTokens();
        if (minimumTokenAmount) setMinimumTokenAmount(minimumTokenAmount);
    }, [address]);


    const modifyToken = (i) => {
        setPrices(null);
        setTokenOneAmount(0);
        setTokenTwoAmount(0);
        if (changeToken === 1) {
            setTokenOne(tokenList[i]);
            fetchDexSwap(tokenList[i].address, tokenTwo.address);
        } else {
            setTokenTwo(tokenList[i]);
            fetchDexSwap(tokenOne.address, tokenList[i].address);
        }
        setIsOpen(false);
    };

    const fetchDexSwap = async (one, two) => {
        try {
            if (isConnected && tokenOneAmount && tokenOneAmount > 0) {
                const ethersProvider = new BrowserProvider(walletProvider);
                const router = new Contract(PancakeSwapRouterAddress, pancakeSwapRouterABI, ethersProvider);
                const inputAmount = parseUnits(tokenOneAmount.toString(), tokenOne.decimals);
                const amounts = await router.getAmountsOut(inputAmount, [one, two]);
                const amountOut = new BigNumber((Number(formatUnits(amounts[1], tokenTwo.decimals)) * 0.99).toString());

                setTokenTwoAmount(amountOut);
            } else {
                setTokenTwoAmount(0);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const sell = async () => {
        if (isConnected) {

            const ethersProvider = new BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner();
            const profitPortalContract = new Contract(ProfitPortalAddress, profitPortalABI, signer);
            try {
                let tx;
                if (isWhitelisted) {
                    tx = await profitPortalContract.takeProfitWhitelisted(parseUnits(tokenOneAmount.toString(), tokenOne.decimals));
                } else {
                    tx = await profitPortalContract.takeProfit(parseUnits(tokenOneAmount.toString(), tokenOne.decimals));
                }

                setIsLoading(true);
                await tx.wait();
                setIsLoading(false);
                setIsSuccess(true);
                fetchData(signer); // Update allowance after approval
                messageApi.success('Sell successful');
                setTokenOneAmount(0);
            } catch (error) {
                setIsLoading(false);
                setIsSuccess(false);
                if (error.toString().includes('liquidity'))
                    messageApi.error('Insufficient liquidity, try later!');
                else if (error.toString().includes('Balance is less'))
                    messageApi.error('Minimum token amount not met');
                else
                    messageApi.error('Sell failed');
                console.error('Sell failed', error);
            }
        }
    };

    const sendTransaction = async (tx) => {
        try {
            setIsLoading(true);
            await tx.wait();
            setIsSuccess(true);
        } catch (error) {
            setIsSuccess(false);
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (isConnected) {
            fetchDexSwap(tokenList[1].address, tokenList[0].address);
            // Set interval to fetch every 10 seconds
            const intervalId = setInterval(() => {
                fetchDexSwap(tokenList[1].address, tokenList[0].address);
            }, 10000); // 10000ms = 10 seconds

            // Clear interval on component unmount
            return () => clearInterval(intervalId);

        }
    }, [tokenOneAmount]);

    useEffect(() => {
        messageApi.destroy();
        if (isLoading) {
            messageApi.open({
                content: 'Waiting for transaction to be mined',
                type: 'loading',
                duration: 0,
            });
        }
    }, [isLoading]);

    useEffect(() => {
        messageApi.destroy();
        if (isSuccess) {
            messageApi.open({
                type: 'success',
                content: 'Transaction Success',
                duration: 2,
            });
        } else if (txDetails.to) {
            messageApi.open({
                type: 'error',
                content: 'Transaction Failed',
                duration: 2,
            });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (txDetails.to && isConnected) {
            sendTransaction(txDetails);
            // message.success('Transaction sent');
        }
    }, [txDetails]);


    async function approve() {
        if (isConnected) {
            const ethersProvider = new BrowserProvider(walletProvider);
            const signer = await ethersProvider.getSigner();
            const TokenContract = new Contract(TokenAddress, tokenABI, signer);
            try {
                const tx = await TokenContract.approve(ProfitPortalAddress, MaxUint256);
                setIsLoading(true);
                await tx.wait();
                setIsLoading(false);
                setIsSuccess(true);
                fetchData(signer); // Update allowance after approval
                messageApi.success('Approval successful');
            } catch (error) {
                setIsLoading(false);
                setIsSuccess(false);
                messageApi.error('Approval failed');
                console.error('Approval failed', error);
            }
        }
    }
    const title = 'CrashCoin OTC Sell & Burn';
    const description = 'CrashCoin OTC Sell & Burn';
    const imageUrl = `${process.env.PUBLIC_URL}/logo192.webp`;
    const url = 'https://app.crashcoin.fun/Burn';

    return (
        <>
            <div>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={imageUrl} />
                    <meta property="og:url" content={url} />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:image" content={imageUrl} />
                </Helmet>
                {/* <h2>{title}</h2>
                <p>{description}</p>
                <img src={imageUrl} alt={title} /> */}
            </div>
            {contextHolder}
            <div className="tradeBox">
                <div className="tradeBoxHeader">
                    <h4>OTC (1% Tax) Sell & Burn</h4>

                </div>
                <div className="inputs">
                    <Input placeholder="0" value={tokenOneAmount} onChange={changeAmount} disabled={!prices} type='number' className="no-spinner input-box" />
                    <div className="referrer-container">
                        <div className="leftH" style={{ fontSize: '1.0em', marginLeft: '5px' }}>
                            <div className="balance-text">
                                Balance: {Number(formatUnits(balance, tokenOne.decimals)).toFixed(2)} CRASH
                            </div>
                        </div>
                        <div className="rightH" style={{ fontSize: '0.8em', marginRight: '5px' }}>
                            <div className="input-container">
                                <div className="input-buttons">
                                    <button onClick={() => setAmountPercentage(25)}>25%</button>
                                    <button onClick={() => setAmountPercentage(50)}>50%</button>
                                    <button onClick={() => setAmountPercentage(75)}>75%</button>
                                    <button onClick={() => setAmountPercentage(100)}>MAX</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Input placeholder="0" value={tokenTwoAmount.toFixed(8)} disabled />
                    <div className="assetOne" >
                        <img src={`${process.env.PUBLIC_URL}/logo192.webp`} style={{ width: '30px', height: '30px' }} alt="assetOnelogo" className="logo" />
                        {tokenOne.ticker}
                    </div>
                    <div className="assetTwo" >
                        <img src={`${process.env.PUBLIC_URL}/bnb-logo.svg`} style={{ width: '30px', height: '30px' }} alt="assetTwologo" className="logo" />
                        {tokenTwo.ticker}
                    </div>
                </div>
                {isConnected > 0 &&
                    <div className="referrer-container">
                        <div className="leftH" style={{ fontSize: '0.8em', marginLeft: '5px' }}>Available Liquidity: </div>
                        <div className="rightH" style={{ fontSize: '0.8em', marginRight: '5px' }}>{Number(formatUnits(liquidity, tokenTwo.decimals)).toFixed(2)} BNB</div>
                    </div>
                }
                {minimumTokenAmount > 0 &&
                    <div className="referrer-container">
                        <div className="leftH" style={{ fontSize: '0.8em', marginLeft: '5px' }}>Minimum Balance: </div>
                        <div className="rightH" style={{ fontSize: '0.8em', marginRight: '5px' }}>{Number(formatUnits(minimumTokenAmount, tokenOne.decimals)).toFixed(2)} CRASH</div>
                    </div>
                }
                {burnedBalance > 0 &&
                    <div className="referrer-container">
                        <div className="leftH" style={{ fontSize: '0.8em', marginLeft: '5px' }}>Total Burned: </div>
                        <div className="rightH" style={{ fontSize: '0.8em', marginRight: '5px' }}>{Number(formatUnits(burnedBalance, tokenOne.decimals)).toFixed(2)} CRASH</div>
                    </div>

                }

                {approval ? (
                    <div className="Button" onClick={approve} disabled={!isConnected}>
                        Enable
                    </div>
                ) : (
                    <div className="Button" onClick={sell} disabled={!tokenOneAmount || tokenOneAmount === 0 || !isConnected}>
                        Sell & Burn
                    </div>
                )}

                {!isActive && !isWhitelisted && <div className='status inactive' style={{ marginBottom: '10px' }}>
                    OTC Sell is currently inactive
                </div>
                }
            </div >
        </>
    );
}

export default Burn;
