const TokenAddress = "0xB3B08C36d8783d22177928Cb7433A346fA27E422";
const ProfitPortalAddress = "0x3511999f552ef49F88C37f9A98D7EABAaF3E9A8e";
const LotteryAddress = "0x03dB6a58D3CCDD2fA04da134D1d9835dC355FDa5";
const PancakeSwapRouterAddress = "0x10ED43C718714eb63d5aA57B78B54704E256024E";

module.exports = {
    TokenAddress,
    ProfitPortalAddress,
    LotteryAddress,
    PancakeSwapRouterAddress
};
