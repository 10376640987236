// src/components/DexScreenerEmbed.js
import React from 'react';

const DexScreenerEmbed = () => {
    const htmlContent = `
<style>#dexscreener-embed{position:relative;width:100%;padding-bottom:125%;}@media(min-width:1400px){#dexscreener-embed{padding-bottom:65%;}}@media(max-width:866px){#dexscreener-embed{padding-bottom:180%;}}#dexscreener-embed iframe{position:absolute;width:100%;height:100%;top:0;left:0;border:0;}</style><div id="dexscreener-embed"><iframe src="https://dexscreener.com/bsc/0x76bc379e81D334FBaC313C237c140fC305b4A84D?embed=1&loadChartSettings=0&trades=1&tabs=0&info=0&chartLeftToolbar=1&chartDefaultOnMobile=1&chartTheme=dark&theme=dark&chartStyle=1&chartType=usd&interval=60"></iframe></div>


`;

    return (
        <div
            dangerouslySetInnerHTML={{ __html: htmlContent }}
        />
    );
};

export default DexScreenerEmbed;